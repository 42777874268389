import React from 'react';
import Slider from 'infinite-react-carousel';
import video from '../assets/intro-video.mp4';

import bannerWelcomeL from '../assets/banner-welcome-l.png'
import bannerWelcomeSm from '../assets/banner-welcome-sm.png'
import bannerEasterL from '../assets/banner-easter-l.png'
import bannerEasterSm from '../assets/banner-easter-sm.png'
import bannerPracticeL from '../assets/banner-practice-l.png'
import bannerPracticeSm from '../assets/banner-practice-sm.png'
import bannerNewYearL from '../assets/banner-happy-new-year-l.png'
import bannerNewYearSm from '../assets/banner-happy-new-year-sm.png'
import groupImg from '../assets/group-color.jpg'

const LandingPage = () =>{
    const isBigScreen = window.innerWidth > 768
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const currentYear = new Date().getFullYear()

    const displayEasterBanner = () => {
        const startDate = new Date(currentYear, 2, 25)
        const endDate = new Date(currentYear, 3, 1)
        return today >= startDate && today <= endDate
    }

    const displaynewYearBanner = () => {
        const startDate = new Date("2023-12-25")
        const endDate = new Date("2024-01-05")

        return today >= startDate && today <= endDate
    }

    const renderNewYearL = displaynewYearBanner() && (                        
        <div className='landingPage__banner'>
            <img className ="landingPage__banner--item" src={bannerNewYearL} alt="Fijne feestdagen en een gelukkig & gezond 2024!"/>
        </div>
    )

    const renderNewYearSm = displaynewYearBanner() && (                        
        <div className='landingPage__banner'>
            <img className ="landingPage__banner--item" src={bannerNewYearSm} alt="Fijne feestdagen en een gelukkig & gezond 2024!"/>
        </div>
    )


    const renderEasterL = displayEasterBanner() && (                        
        <div className='landingPage__banner'>
            <img className ="landingPage__banner--item" src={bannerEasterL} alt="Wij wensen u fijne paasdagen!"/>
        </div>
    )

    const renderEasterSm = displayEasterBanner() && (                        
        <div className='landingPage__banner'>
            <img className ="landingPage__banner--item" src={bannerEasterSm} alt="Wij wensen u fijne paasdagen!"/>
        </div>
    )

    const renderLandingImgL = (
        <div className='landingPage__banner'>
            <img className ="landingPage__container__img--item" src ={groupImg} alt="Dodewaards Mannenkoor"/>
        </div>
    )

    return(
    <div className ="landingPage">
            <video className = "landingPage__video" autoPlay muted loop playsInline>
                <source src = {video} type="video/mp4" />
            </video>
        <div className ="landingPage__container">
            <div className='landingPage__container--wrapper'>
                {isBigScreen && renderLandingImgL}
                {isBigScreen &&
                    <Slider 
                        arrows={false}
                        autoplay
                        autoplayScroll = {1}
                        autoplaySpeed ={5000}
                    >
                        <div className='landingPage__banner'>
                            <img className ="landingPage__banner--item" src ={bannerWelcomeL} alt="Welkom  op de website van het Dodewaards Mannenkoor"/>
                        </div>
                        {renderNewYearL}
                        {renderEasterL}
                        <div className='landingPage__banner'>
                            <img className ="landingPage__banner--item" src={bannerPracticeL} alt="Repeteren"/>
                        </div>
                    </Slider>
                }
                {!isBigScreen &&
                    <Slider 
                        arrows={false}
                        autoplay
                        autoplayScroll = {1}
                        autoplaySpeed ={4500}
                    >
                        <div className='landingPage__banner'>
                            <img className ="landingPage__banner--item" src ={bannerWelcomeSm} alt="Welkom  op de website van het Dodewaards Mannenkoor"/>
                        </div>
                        {renderNewYearSm}
                        {renderEasterSm}
                        <div className='landingPage__banner'>
                            <img className ="landingPage__banner--item" src={bannerPracticeSm} alt="Repeteren"/>
                        </div>
                    </Slider>
                }
            </div>
        </div>
    </div>
    )
}

export default LandingPage;
